import React from "react";
import PropTypes from "prop-types";
import { ScheduleAdd } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import CardIconButton from "./CardIconButton";

const ScheduleIconButton = ({ onClick, ...rest }) => (
  <Tooltip overlay="Add to schedule">
    <CardIconButton
      Icon={ScheduleAdd}
      onClick={e => {
        // Stop propagation since we handle clicks on Wrapper
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        onClick(e);
      }}
      {...rest}
    />
  </Tooltip>
);

ScheduleIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ScheduleIconButton;
