import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flex from "app/components/Flex";
import { P1, H2 } from "app/components/Typography";
import Div from "app/components/Div";
import ProgressBar from "app/components/ProgressBar";
import Icon, { ChevronDown, ChevronUp, CheckSquare } from "app/components/Icon";
import Sections from "./Sections";

const ToggleBlockButton = ({ isBlockExpanded, onClick, color }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height="100%"
      onClick={onClick}
      p="20px"
      cursor="pointer"
    >
      {isBlockExpanded ? (
        <Icon color={color} as={ChevronUp} />
      ) : (
        <Icon color={color} as={ChevronDown} />
      )}
    </Flex>
  );
};

const StyledProgressBar = styled(ProgressBar)`
  border-radius: 2px;
`;

const Block = ({
  progress = {},
  superscript = "",
  title = "",
  sortIndex = -1,
  sectionsCount = 0,
}) => {
  const isBlockCompleted = progress.isCompleted;
  const { sectionsCompletedCount = 0, classesCompletedPercent = 0 } = progress;
  const [isBlockExpanded, setIsBlockExpanded] = useState(!isBlockCompleted);

  return (
    <>
      <Flex
        className="watch-scroll"
        id={`program-block-el-${sortIndex}`}
        height="99px"
        mt="12px"
        mb="12px"
        minWidth="100%"
        borderRadius={[4]}
        bg={isBlockCompleted ? "monochrome.8" : "monochrome.1"}
        alignItems="center"
        scrollMargin="76px"
      >
        {isBlockCompleted && (
          <Flex
            justifyContent="center"
            bg="bulbaGreen"
            height="100%"
            width="56px"
            borderTopLeftRadius={4}
            borderBottomLeftRadius={4}
          >
            <Icon as={CheckSquare} width="32px" />
          </Flex>
        )}
        <Flex mt="20px" ml="20px" mb="20px" flexDirection="column">
          <H2
            onClick={() => setIsBlockExpanded(!isBlockExpanded)}
            cursor="pointer"
            color={isBlockCompleted ? "monochrome.1" : "monochrome.8"}
            mb="8px"
          >
            {superscript}: {title}
          </H2>
          <P1
            color={isBlockCompleted ? "bulbaGreen" : "monochrome.6"}
            fontWeight="700"
            letterSpacing="1px"
            textTransform="uppercase"
          >
            {sectionsCompletedCount} of {sectionsCount} Days Completed
          </P1>
        </Flex>
        <Flex ml={{ _: "auto" }} alignItems="center">
          <Div
            ml={4}
            mr={4}
            display={{ _: "none", sm: "block" }}
            width={{ md: "242px", lg: "350px" }}
          >
            <StyledProgressBar
              justifySelf="flex-end"
              height="6px"
              width="100%"
              bg="monochrome.6"
              color={isBlockCompleted ? "bulbaGreen" : "blue"}
              percent={classesCompletedPercent}
            />
          </Div>
          <ToggleBlockButton
            isBlockExpanded={isBlockExpanded}
            onClick={() => setIsBlockExpanded(!isBlockExpanded)}
            color={isBlockCompleted ? "white" : "monochrome.8"}
          />
        </Flex>
      </Flex>
      {isBlockExpanded && (
        <>
          <Div height="48px" />
          <Flex>
            <Div
              mr="26px"
              height="100%"
              borderRadius="4px"
              width="6px"
              bg={isBlockCompleted ? "bulbaGreen" : "monochrome.1"}
            />

            <Flex flexDirection="column" width="100%">
              <Sections blockSortIndex={sortIndex} />
            </Flex>
          </Flex>
          <Div height="48px" />
        </>
      )}
    </>
  );
};

export default memo(Block);

ToggleBlockButton.defaultProps = {
  isBlockExpanded: false,
};

ToggleBlockButton.propTypes = {
  isBlockExpanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

Block.propTypes = {
  progress: PropTypes.shape({}),
  superscript: PropTypes.string,
  title: PropTypes.string,
  sortIndex: PropTypes.number,
  sectionsCount: PropTypes.number,
};
