/* eslint-disable camelcase */
import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import convertTimeObjectToSeconds from "helpers/convertTimeObjectToSeconds";
import { userSelectsClassAction } from "modules/content";
import useToggleSaveClass from "hooks/Classes/useToggleSaveClass";
import env from "helpers/env";
import { useAccountMode } from "hooks/useAccountMode";
import { useCanUserTakeClass } from "hooks/Classes";
import Card from "./Card";

const ProgramClassCard = ({
  classData: classDataProps,
  isProgressCompleted,
  isSaved,
  thumbnailWidth,
  selectedFrom,
  ...classCardProps
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const toggleSaveClassMutation = useToggleSaveClass();
  const { accountMode } = useAccountMode();
  const { canUserTakeClass } = useCanUserTakeClass({
    classId: classDataProps?.id,
  });

  if (!classDataProps) {
    return null;
  }

  const classData = {
    ...classDataProps,
    canUserTakeClass,
  };

  const goToClass = e => {
    const selectClassParams = {
      classData,
      component: selectedFrom,
      element: "StartClassButton",
      entryRoute: location.pathname,
      accountMode,
    };
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    dispatch(userSelectsClassAction(selectClassParams));
  };

  let progressTimeInSeconds = 0;
  const { progress } = classData;
  if (progress && progress.time) {
    progressTimeInSeconds = convertTimeObjectToSeconds(progress.time);
  }
  const progressPercent =
    (progressTimeInSeconds / classData?.duration_in_seconds) * 100;

  const props = {
    progressPercent,
    isProgressCompleted,
    isSaved,
    contentData: classData,
    thumbnailSrc: `${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
      classData.slug
    }-basic.jpg?w=${thumbnailWidth}`,
    thumbnailPlaceholderSrc: `${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
      classData.slug
    }-basic.jpg?blur=200&px=16&auto=format`,
    selectedFrom,
  };

  return (
    <Card
      {...classCardProps}
      {...props}
      goToContent={goToClass}
      saveUserContent={() => {
        toggleSaveClassMutation({
          component: selectedFrom,
          variables: {
            classId: classData.id,
            willSave: !isSaved,
            programClassRefId: classData.refId,
          },
          classData,
        });
      }}
    />
  );
};

ProgramClassCard.defaultProps = {
  isProgressCompleted: false,
  isSaved: false,
  progressPercent: 0,
  selectedFrom: "ProgramClassCard",
  thumbnailWidth: "1200",
};

ProgramClassCard.propTypes = {
  classData: PropTypes.shape({}).isRequired,
  isSaved: PropTypes.bool,
  isProgressCompleted: PropTypes.bool,
  progressPercent: PropTypes.number,
  selectedFrom: PropTypes.string,
  thumbnailWidth: PropTypes.string,
};

export default ProgramClassCard;
