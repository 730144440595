import React, { memo } from "react";
import { useParams } from "react-router";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import Block from "./Block";

const Blocks = () => {
  const { slug } = useParams();
  const [getProgramV2] = useGetProgramsV2({
    variables: { slug },
  });
  const blocks = getProgramV2?.blocks || [];

  return blocks.map(blockData => (
    <Block
      progress={blockData.progress}
      superscript={blockData.superscript}
      title={blockData.title}
      sortIndex={blockData.sortIndex}
      sectionsCount={blockData.sectionsCount}
    />
  ));
};

export default memo(Blocks);
