import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useParams } from "react-router";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import { H1, P1 } from "app/components/Typography";
import Icon, { Schedule, Alarm } from "app/components/Icon";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import Img from "app/components/Img";
import env from "helpers/env";
import Blocks from "./components/Blocks";

const StrokeText = styled(Text)`
  color: ${({ color }) => color};
  font-weight: 800;
  -webkit-text-stroke: ${({ theme, textStroke }) => theme.colors[textStroke]}
    2px;
`;

const ClassInfoTag = ({ children, icon, color }) => {
  return (
    <Flex mr={3} alignItems="center" mb={3}>
      <Icon height="17px" width="17px" color={color} mr={2} as={icon} />
      <P1 fontSize="16px" fontWeight="600">
        {children}
      </P1>
    </Flex>
  );
};

const CompletionBanner = () => (
  <Img
    mt="12px"
    mb="72px"
    width="100%"
    src={`${env("PUBLIC_ASSET_PATH")}/completion-banner.svg`}
  />
);

const ProgramClasses = () => {
  const { slug } = useParams();
  const [{ progress = {}, totalSectionCount = 0 }, loading] = useGetProgramsV2({
    variables: { slug },
  });
  const {
    sectionsCompletedCount = 0,
    minutesSpent = 0,
    percentCompleted = 0,
    completedDate = null,
  } = progress;
  const isProgramCompleted = !!completedDate;
  return (
    <>
      <H1 mb="32px">Classes</H1>
      <StrokeText
        fontSize={{ xs: "44px", sm: "64px" }}
        textStroke={isProgramCompleted ? "bulbaGreen" : "blue"}
        color={isProgramCompleted ? "bulbaGreen" : "white"}
        mb={4}
      >
        {Math.floor(percentCompleted * 100)}% Completed
      </StrokeText>
      <Flex mb={3} flexDirection={{ _: "column", md: "row" }}>
        <ClassInfoTag
          color={isProgramCompleted ? "monochrome.8" : "blue"}
          icon={Schedule}
        >
          {sectionsCompletedCount} of {totalSectionCount} Days Complete
        </ClassInfoTag>
        <ClassInfoTag
          color={isProgramCompleted ? "monochrome.8" : "blue"}
          icon={Alarm}
        >
          {Math.round(minutesSpent)} Minutes Spent
        </ClassInfoTag>
      </Flex>
      <Flex flexDirection="column">
        {!loading && <Blocks />}
        {isProgramCompleted && <CompletionBanner />}
      </Flex>
    </>
  );
};

export default ProgramClasses;

ClassInfoTag.propTypes = {
  children: PropTypes.shape({}).isRequired,
  icon: PropTypes.elementType.isRequired,
  color: PropTypes.string.isRequired,
};
