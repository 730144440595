import React from "react";
import Flex from "app/components/Flex";
import { useDispatch } from "react-redux";
import { toggleAddClassModalAction } from "modules/components";
import useToggleClassComplete from "hooks/ProgramsV2/useToggleClassComplete";
import useToggleSaveClass from "hooks/Classes/useToggleSaveClass";
import { DropdownMenu, DropdownOption } from "app/components/Dropdown";
import {
  Heart,
  HeartFilled,
  ScheduleAdd,
  CheckSquare,
} from "app/components/Icon";
import { AddClassToPlaylistOption } from "app/components/ClassComponents/DropdownMenuOptions";
import { MenuButton } from "./MenuButton";

interface Props {
  blockSortIndex: number;
  sectionSortIndex: number;
  classData: any;
  isProgramClassCompleted: boolean;
  isProgramClassSaved: boolean;
  programClassRefId: string;
  programSlug: string;
}

export const DropdownMenuOptions = ({
  blockSortIndex,
  sectionSortIndex,
  classData,
  isProgramClassCompleted,
  isProgramClassSaved,
  programClassRefId,
  programSlug,
}: Props) => {
  const dispatch = useDispatch();
  const toggleClassCompleteMutation = useToggleClassComplete();
  const toggleSaveProgramClassMutation = useToggleSaveClass();

  return (
    <Flex>
      <DropdownMenu menuButton={MenuButton}>
        <AddClassToPlaylistOption
          classNumber={Number(classData.id)}
          classId={classData.studioClassId}
        />
        <DropdownOption
          text={
            isProgramClassCompleted
              ? "Mark video as incomplete"
              : "Mark video as complete"
          }
          Svg={CheckSquare}
          onClick={() => {
            toggleClassCompleteMutation({
              variables: {
                programClassRefId,
                isComplete: !isProgramClassCompleted,
              },
              sectionSortIndex,
              blockSortIndex,
              slug: programSlug,
            });
          }}
        />
        <DropdownOption
          Svg={isProgramClassSaved ? HeartFilled : Heart}
          onClick={() => {
            toggleSaveProgramClassMutation({
              component: "ProgramClasses",
              variables: {
                classId: classData.id,
                willSave: !isProgramClassSaved,
                programClassRefId,
              },
              classData,
            });
          }}
          text={isProgramClassSaved ? "Class Saved" : "Save Class"}
        />
        <DropdownOption
          Svg={ScheduleAdd}
          onClick={() => {
            dispatch(
              toggleAddClassModalAction(classData.id, programClassRefId)
            );
          }}
          text="Add to Schedule"
        />
      </DropdownMenu>
    </Flex>
  );
};
