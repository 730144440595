import React, { memo } from "react";
import PropTypes from "prop-types";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import { useParams } from "react-router";
import Section from "./Section";

const Sections = ({ blockSortIndex }) => {
  const { slug } = useParams();
  const [getProgramV2] = useGetProgramsV2({
    variables: { slug },
  });
  const sections = getProgramV2?.blocks?.[blockSortIndex]?.sections || [];

  return sections.map((section, i) => (
    <Section
      isFirstSection={i === 0}
      blockSortIndex={blockSortIndex}
      title={section.title}
      durationInMins={section.durationInMins}
      classesCount={section.classesCount}
      sortIndex={section.sortIndex}
      progress={section.progress}
    />
  ));
};

export default memo(Sections);

Sections.propTypes = {
  blockSortIndex: PropTypes.number.isRequired,
};
