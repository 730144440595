/* eslint-disable react/prop-types */
import React, { useRef, memo } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { LimitLineText, H2, H4, P1 } from "app/components/Typography";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import ProgramClassCard from "app/components/ProgramClassCard";
import Grid from "app/components/Grid";
import Text from "app/components/Text";
import Icon, { Check } from "app/components/Icon";
import { DropdownMenuOptions } from "./DropdownMenuOptions";

const ClassTitle = ({ title }) => (
  <H4 letterSpacing="0.5px">
    <LimitLineText lineClamp={2}>{title}</LimitLineText>
  </H4>
);

const Classes = ({ blockSortIndex, sectionSortIndex }) => {
  const { slug } = useParams();
  const [getProgramV2] = useGetProgramsV2({
    variables: { slug },
  });

  const classes =
    getProgramV2?.blocks?.[blockSortIndex]?.sections?.[sectionSortIndex]
      ?.classes || [];

  return classes.map((classData, index) => (
    <Flex
      key={classData.slug}
      flexDirection="column"
      justifyContent="space-between"
    >
      <ClassNumberWithBorder
        blockSortIndex={blockSortIndex}
        sectionSortIndex={sectionSortIndex}
        index={index}
        title={classData.title}
        classData={classData}
        isProgramClassCompleted={Boolean(
          classData.progress.programClassProgress.find(
            program => program.programSlug === slug
          )?.isCompleted
        )}
        slug={slug}
      />
    </Flex>
  ));
};

const ClassNumberWithBorder = memo(
  ({ classData, index, slug, blockSortIndex, sectionSortIndex }) => {
    const ref = useRef();
    const isSubscribedUser = useSelector(
      ({ user }) => user.subscription.activeSubscription
    );

    const {
      title,
      isSaved: isProgramClassSaved,
      progress: { programClassProgress },
    } = classData;
    const isProgramClassCompleted =
      Boolean(
        programClassProgress.find(program => program.programSlug === slug)
          ?.isCompleted
      ) || false;

    return (
      <Flex flexDirection="column">
        <Flex height="50px" mt={3} mb={3} alignItems="center">
          <Flex
            alignItems="center"
            justifyContent="center"
            width="32px"
            minWidth="32px"
            height="32px"
            minHeight="32px"
            mr="12px"
            borderColor={
              isProgramClassCompleted ? "bulbaGreen" : "monochrome.1"
            }
            borderWidth="3px"
            borderStyle="solid"
            borderRadius="4px"
          >
            {isProgramClassCompleted ? (
              <Icon width="20px" as={Check} color="bulbaGreen" p="2px" />
            ) : (
              <Text
                fontWeight="bold"
                fontSize="14px"
                lineHeight="1"
                color="monochrome.8"
              >
                {index + 1}
              </Text>
            )}
          </Flex>
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <ClassTitle title={title} />
            <Flex
              ref={ref}
              width="50px"
              height="50px"
              alignItems="center"
              justifyContent="flex-end"
            >
              {isSubscribedUser && (
                <DropdownMenuOptions
                  blockSortIndex={blockSortIndex}
                  sectionSortIndex={sectionSortIndex}
                  classData={classData}
                  programClassRefId={classData.refId}
                  isProgramClassCompleted={isProgramClassCompleted}
                  isProgramClassSaved={isProgramClassSaved}
                  programSlug={slug}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
        <ProgramClassCard
          classData={classData}
          isSaved={isProgramClassSaved}
          isProgressCompleted={isProgramClassCompleted}
        />
      </Flex>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.classData, nextProps.classData);
  }
);

const Section = ({
  isFirstSection,
  blockSortIndex,
  title = "",
  durationInMins = 0,
  sortIndex = -1,
  classesCount = 0,
  progress: { isCompleted = false } = {},
}) => {
  return (
    <Div mt={isFirstSection ? "0px" : "48px"}>
      <H2 mb={3}>{title}</H2>
      <P1
        color={isCompleted ? "bulbaGreen" : "monochrome.5"}
        bg={isCompleted ? "monochrome.8" : ""}
        fontWeight="700"
        width="fit-content"
        px={isCompleted ? "6px" : "0"}
        borderRadius={isCompleted ? "2px" : "0"}
        mb="8px"
      >
        {isCompleted
          ? `${classesCount} OF ${classesCount} COMPLETED`
          : `${classesCount} CLASSES • ${durationInMins || 0} MIN TOTAL`}
      </P1>
      <Grid
        gridTemplateColumns={{
          sm: "repeat(1)",
          md: "repeat(2, minmax(200px, 1fr))",
          lg: "repeat(3, minmax(200px, 1fr))",
        }}
        gridGap="24px"
      >
        <Classes blockSortIndex={blockSortIndex} sectionSortIndex={sortIndex} />
      </Grid>
    </Div>
  );
};

export default memo(Section);

ClassTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

ClassNumberWithBorder.propTypes = {
  blockSortIndex: PropTypes.number.isRequired,
  sectionSortIndex: PropTypes.number.isRequired,
  classData: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
};

Section.propTypes = {
  isFirstSection: PropTypes.bool.isRequired,
  blockSortIndex: PropTypes.number.isRequired,
  title: PropTypes.string,
  durationInMins: PropTypes.string,
  sortIndex: PropTypes.number,
  classesCount: PropTypes.number,
};
