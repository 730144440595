/* eslint-disable consistent-return */
import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import useOnScreen from "hooks/useOnScreen";
import Button from "app/components/Button";
import HeartIconButton from "app/components/Button/IconButton/HeartIconButton";
import ScheduleIconButton from "app/components/Button/IconButton/ScheduleIconButton";
import VolumeIconButton from "app/components/Button/IconButton/VolumeIconButton";
import Icon, {
  CheckCircle,
  Lock,
  LockedIndicatorV2,
} from "app/components/Icon";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { withTheme } from "styled-components";
import {
  toggleAddClassModalAction,
  toggleSettingsVolumeAction,
} from "modules/components";
import Thumbnail from "app/components/Thumbnail";
import Text from "app/components/Text";
import { IS_MOBILE } from "constants/index";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import {
  userPreviewingContentAction,
  userStartPreviewingContentAction,
  userStopPreviewingContentAction,
} from "modules/content";
import { H7 } from "app/components/Typography";
import queryString from "query-string";
import {
  Container,
  ContentAttribute,
  OverlayShadow,
  ThumbnailWrapper,
  CARD_PADDING,
  LockOverlay,
  StyledProgressBar,
  FadeOutThumbnail,
  Wrapper,
} from "./components";

let mouseEnterTimeout = null;

const Card = ({
  contentData,
  goToContent,
  hasShadow,
  hideProgress,
  isActive,
  isProgressCompleted,
  isSaved,
  isSubscribeModalShowing,
  onMouseEnter,
  onClick,
  progressPercent,
  saveUserContent,
  selectedFrom,
  settingsVolume,
  thumbnailPlaceholderSrc,
  thumbnailSrc,
  toggleAddClassModal,
  toggleSettingsVolume,
  userPreviewingContent,
  userStartPreviewingContent,
  userStopPreviewingContent,
  ...rest
}) => {
  const wrapperRef = useRef();
  const containerRef = useRef();
  const [isMoreDetailsShowing, toggleMoreDetailsShowing] = useState(false);
  const [isPreviewVideoShowing, togglePreviewVideoShowing] = useState(false);
  const publishDate = contentData.publish_date || contentData.publishDate;
  const published = moment().isAfter(publishDate) || !publishDate;
  const onScreen = useOnScreen(wrapperRef, "-100px");

  const compileQueryParams = useCallback(() => {
    const queryParams = {};

    if (contentData?.playlistId) {
      queryParams.playlist = contentData.playlistId;
    }

    if (contentData?.refId) {
      queryParams.classRefId = contentData.refId;
    }

    return queryString.stringifyUrl({
      url: `/class/${contentData.id}`,
      query: queryParams,
    });
  }, [contentData?.playlistId, contentData?.refId]);

  useOnClickOutside(wrapperRef, () => {
    if (IS_MOBILE && isMoreDetailsShowing) {
      toggleMoreDetails(false);
    }
  });

  const handleClick = e => {
    e.preventDefault(); // To ignore link tag href
    if (onClick) {
      return onClick();
    }
    if (IS_MOBILE) {
      toggleMoreDetails(true);
    } else {
      goToContent(e);
    }
  };

  const handleMouseEnter = () => {
    if (onMouseEnter) {
      return onMouseEnter();
    }
    if (IS_MOBILE || !published) {
      return;
    }
    if (!isMoreDetailsShowing) {
      mouseEnterTimeout = setTimeout(() => {
        toggleMoreDetails(true);
      }, 200);
    }
  };

  const handleMouseLeave = () => {
    if (IS_MOBILE || !published) {
      return;
    }

    toggleMoreDetails(false);
    clearTimeout(mouseEnterTimeout);
  };

  const toggleMoreDetails = useCallback(
    isToggled => {
      toggleMoreDetailsShowing(isToggled);
      togglePreviewVideoShowing(isToggled);
      if (isPreviewVideoShowing) {
        userStartPreviewingContent({
          contentData,
          component: selectedFrom,
        });
      }
      if (!isPreviewVideoShowing) {
        userStopPreviewingContent();
      }
    },
    [
      contentData,
      isPreviewVideoShowing,
      selectedFrom,
      userStartPreviewingContent,
      userStopPreviewingContent,
    ]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (isMoreDetailsShowing && !IS_MOBILE) {
        toggleMoreDetails(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, [isMoreDetailsShowing, toggleMoreDetails]);

  useEffect(
    function onAppearOnScreen() {
      if (!onScreen && isPreviewVideoShowing) {
        toggleMoreDetails(false);
      }
    },
    [isPreviewVideoShowing, onScreen, toggleMoreDetails]
  );

  const previewVideoUrl = useMemo(() => {
    if (contentData.preview_url) {
      return contentData.preview_url;
    }
    return contentData?.content?.assets?.previewVideoURL;
  }, [contentData]);

  return (
    <Wrapper
      href={compileQueryParams()} // used to allow for Open in New Tab functionality
      hasShadow={hasShadow}
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      active={isMoreDetailsShowing}
      {...rest}
    >
      <Container ref={containerRef} hasShadow={hasShadow} isActive={isActive}>
        <ThumbnailWrapper>
          {isPreviewVideoShowing && (
            <Div
              position="absolute"
              height="100%"
              top={0}
              right={0}
              // To be honest I don't why this works
              // Something something math
              pr="100%"
            >
              <Div position="absolute" width="100%">
                {previewVideoUrl && (
                  <ResponsiveVideoPlayer
                    position="absolute"
                    playing
                    playsinline
                    controls={false}
                    volume={1}
                    muted={!settingsVolume}
                    url={previewVideoUrl}
                    onProgress={videoProgress => {
                      userPreviewingContent({
                        contentData,
                        component: selectedFrom,
                        previewDuration: Math.floor(
                          videoProgress.playedSeconds
                        ),
                      });
                    }}
                    loop
                    showLoader={false}
                    height="100%"
                    width="auto"
                  />
                )}
              </Div>
            </Div>
          )}
          <FadeOutThumbnail
            unmountOnExit
            timeout={500}
            in={!isPreviewVideoShowing}
          >
            <Thumbnail
              width="auto"
              height="100%"
              placeholderSrc={thumbnailPlaceholderSrc}
              src={thumbnailSrc}
            />
          </FadeOutThumbnail>
          <OverlayShadow height={isProgressCompleted ? "75%" : "50%"} />
          {isMoreDetailsShowing && (
            <Flex
              position="absolute"
              width="100%"
              height="100%"
              justifyContent="flex-end"
              alignItems="flex-end"
              pr={3}
              pb={3}
            >
              <Flex justifyContent="space-between" width="84px">
                <VolumeIconButton
                  isActive={Boolean(settingsVolume)}
                  onClick={e => {
                    e.preventDefault();
                    toggleSettingsVolume(settingsVolume ? 0 : 1);
                  }}
                  color="monochrome.0"
                />
                <ScheduleIconButton
                  onClick={e => {
                    e.preventDefault();
                    toggleAddClassModal(contentData.id, contentData.refId);
                  }}
                  color="monochrome.0"
                />
                <HeartIconButton
                  isActive={isSaved}
                  onClick={e => {
                    e.preventDefault();
                    saveUserContent();
                  }}
                  color="monochrome.0"
                />
              </Flex>
            </Flex>
          )}
          <Flex>
            <Div>
              <ContentAttribute
                value={contentData.duration}
                position="absolute"
                bottom="0"
                pb={3}
                color="monochrome.0"
                pl={3}
                fontSize="14px"
              />
            </Div>
          </Flex>
          {isMoreDetailsShowing && (
            <Button
              cursor={isSubscribeModalShowing && "not-allowed"}
              height="24px"
              width="74px"
              position="absolute"
              top={CARD_PADDING}
              right={CARD_PADDING}
              p={0}
              onClick={goToContent}
            >
              <Flex alignItems="center">
                {!contentData.canUserTakeClass && (
                  <Icon
                    color="white"
                    height="14px"
                    mr="5px"
                    backgroundColor="transparent"
                    as={LockedIndicatorV2}
                  />
                )}
                <H7>Play</H7>
              </Flex>
            </Button>
          )}
          {!isMoreDetailsShowing && !contentData.canUserTakeClass && (
            <Div
              backgroundColor="monochrome.10"
              borderRadius="2px"
              color="white"
              width="18px"
              height="18px"
              mr="5px"
              position="absolute"
              top={2}
              left={2}
            >
              <Icon color="white" as={LockedIndicatorV2} />
            </Div>
          )}
          <StyledProgressBar
            percent={progressPercent}
            hide={!progressPercent}
          />
          {!published && (
            <LockOverlay>
              <Icon width="20px" as={Lock} />
              <p>
                Available&nbsp;
                {moment(contentData.publish_date || contentData.publishDate)
                  .tz("America/Los_Angeles")
                  .format("MMM D ha z")}
              </p>
            </LockOverlay>
          )}
          {!hideProgress && !isPreviewVideoShowing && isProgressCompleted && (
            <>
              <Div
                width="100%"
                height="100%"
                bg="black"
                position="absolute"
                top={0}
                bottom={0}
                opacity=".4"
              />
              <Flex
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                top={0}
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Icon
                    width="50px"
                    height="50px"
                    as={CheckCircle}
                    color="bulbaGreen"
                  />
                  <Text
                    mt={2}
                    fontSize="9px"
                    color="bulbaGreen"
                    letterSpacing="0.1em"
                    lineHeight="13px"
                    textTransform="uppercase"
                    fontWeight="bold"
                  >
                    Completed
                  </Text>
                </Flex>
              </Flex>
            </>
          )}
        </ThumbnailWrapper>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = ({ auth, components, content, user }) => ({
  auth,
  hasActiveSubscription: user.subscription.activeSubscription,
  previewingId: content.previewingId,
  navbarHeight: components.Navbar.height,
  settingsVolume: components.Settings.volume,
});

const mapDispatchToProps = {
  toggleAddClassModal: toggleAddClassModalAction,
  toggleSettingsVolume: toggleSettingsVolumeAction,
  userPreviewingContent: userPreviewingContentAction,
  userStopPreviewingContent: userStopPreviewingContentAction,
  userStartPreviewingContent: userStartPreviewingContentAction,
};

Card.defaultProps = {
  completedClassCount: 0,
  hasShadow: false,
  hideProgress: false,
  parentRef: null,
  progressPercent: null,
  previewingId: null,
  isActive: false,
  isProgressCompleted: null,
  isSaved: false,
  isMoreDetailsShowing: false,
  totalClassCount: 0,
};

Card.propTypes = {
  auth: PropTypes.shape({}).isRequired,
  completedClassCount: PropTypes.number,
  contentData: PropTypes.shape({}).isRequired,
  hasShadow: PropTypes.bool,
  hasActiveSubscription: PropTypes.bool.isRequired,
  hideProgress: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  goToContent: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  navbarHeight: PropTypes.number.isRequired,
  parentRef: PropTypes.shape({}),
  previewingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool,
  isProgressCompleted: PropTypes.bool,
  isSaved: PropTypes.bool,
  isSubscribeModalShowing: PropTypes.bool.isRequired,
  progressPercent: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  saveUserContent: PropTypes.func.isRequired,
  selectedFrom: PropTypes.string.isRequired,
  settingsVolume: PropTypes.number.isRequired,
  isMoreDetailsShowing: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
  thumbnailPlaceholderSrc: PropTypes.string.isRequired,
  thumbnailSrc: PropTypes.string.isRequired,
  toggleAddClassModal: PropTypes.func,
  toggleSettingsVolume: PropTypes.func.isRequired,
  totalClassCount: PropTypes.number,
  userPreviewingContent: PropTypes.func.isRequired,
  userStartPreviewingContent: PropTypes.func.isRequired,
  userStopPreviewingContent: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(Card))
);
