import { useMutation } from "@apollo/client";
import { cloneDeep } from "lodash";
import { TOGGLE_CLASS_COMPLETE_MUTATION } from "graphql/mutations";
import { GET_PROGRAM_V2_OVERVIEW } from "graphql/queries";

// @TODO colocate
export default () => {
  const [toggleClassCompleteMutation, { client }] = useMutation(
    TOGGLE_CLASS_COMPLETE_MUTATION
  );

  return ({ variables, blockSortIndex, sectionSortIndex, slug }) => {
    const { programClassRefId, isComplete } = variables;
    const { getProgramV2 } = client.readQuery({
      query: GET_PROGRAM_V2_OVERVIEW,
      variables: { slug },
    });

    const classesInSection =
      getProgramV2.blocks[blockSortIndex].sections[sectionSortIndex].classes;
    const classSortIndex = classesInSection.findIndex(
      ({ refId }) => refId === programClassRefId
    );
    const progressIndex = classesInSection[
      classSortIndex
    ].progress.programClassProgress.findIndex(
      ({ programSlug }) => programSlug === slug
    );

    const clonedProgramData = cloneDeep(getProgramV2);
    const mutableProgress =
      clonedProgramData.blocks[blockSortIndex].sections[sectionSortIndex]
        .classes[classSortIndex].progress;

    mutableProgress.programClassProgress[
      progressIndex
    ].isCompleted = isComplete;
    mutableProgress.completedClassCount += 1;

    return toggleClassCompleteMutation({
      variables: {
        programClassRefId,
        isComplete,
      },
      optimisticResponse: {
        __typename: "Mutation",
        toggleClassComplete: {
          __typename: "ProgramV2",
          ...clonedProgramData,
        },
      },
    });
  };
};
